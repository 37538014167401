<template>
  <div class="mod-config" style="">
    <!----------------------------------------- 主列表 --------------------------------------->
    <!---------------页眉相关 ----------------->
    <Row style="margin-top: 10px">
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>服务机构与服务对象绑定信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunjgyfwdxbd"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.jgyfwdxbdColumns"
                  :data="dataMain.jgyfwdxbdData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="AgeLoading"
          >
            <template slot="action"  slot-scope="props">
              <Input v-model="props.row.number"
                      :active-change="false"
                      @on-blur="oldManSave('服务机构与服务对象绑定信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="6" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>政府信息公示窗口</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunRs"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
              <Button
                      type="primary"
                      @click="addZfxx"
                      icon="md-add"
                      style="margin-right: 5px"
              >新增</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.fwryRsColumns"
                  :data="dataMain.fwryRsData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="RstableLoading"
          >
            <template slot="name"  slot-scope="props">
              <Input type="textarea" v-model="props.row.name"
                     :active-change="false"  maxlength="300" show-word-limit
                     @on-blur="oldManSave('政府信息公示窗口',props)"
              />
            </template>
            <template slot="action"  slot-scope="props">
              <Poptip placement="left-start"
                      confirm
                      title="确定删除吗？"
                      @on-ok="delZf(props.row)">
                <Button type="primary" icon="md-trash" ></Button>
              </Poptip>
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="12" style="padding: 0 5px">
        <Card>
          <div style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        ">
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>培训学校坐标信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunNl"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.oldNlColumns"
                  :data="dataMain.orderManNlData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="tableJgLoading"
          >
            <template slot="value5"  slot-scope="props">
              <Input v-model="props.row.value5"
                     :active-change="false"
                     @on-blur="oldManSave('培训学校坐标信息',props)"
              />
            </template>
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('培训学校坐标信息',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('培训学校坐标信息',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('培训学校坐标信息',props)"
              />
            </template>
            <template slot="jd"  slot-scope="props">
              <Input v-model="props.row.jd"
                     :active-change="false"
                     @on-blur="oldManSave('培训学校坐标信息',props)"
              />
            </template>
            <template slot="wd"  slot-scope="props">
              <Input v-model="props.row.wd"
                     :active-change="false"
                     @on-blur="oldManSave('培训学校坐标信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
    </Row>
    <Row style="margin-top: 10px">
      <Col span="12" style="padding: 0 5px">
        <Card>
          <div style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        ">
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>日间照料机构坐标信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunFwryJg"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>

          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.rjzlColumns"
                  :data="dataMain.rjzlData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="rjzlTableLoading"
          >
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('日间照料机构坐标信息',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('日间照料机构坐标信息',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('日间照料机构坐标信息',props)"
              />
            </template>
            <template slot="jd"  slot-scope="props">
              <Input v-model="props.row.jd"
                     :active-change="false"
                     @on-blur="oldManSave('日间照料机构坐标信息',props)"
              />
            </template>
            <template slot="wd"  slot-scope="props">
              <Input v-model="props.row.wd"
                     :active-change="false"
                     @on-blur="oldManSave('日间照料机构坐标信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
      <Col span="12" style="padding: 0 5px">
        <Card>
          <div
                  style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
        "
          >
            <div style="display: flex">
              <div style="padding-left: 20px;font-size: 18px;font-weight: bold">
                <span>社区坐标信息</span>
              </div>
            </div>
            <div>
              <Button
                      type="primary"
                      @click="getFunFwxmsf"
                      icon="md-search"
                      style="margin-right: 5px"
              >查询</Button
              >
            </div>
          </div>
          <!---------------页眉相关 END----------------->
          <!---------------表格相关 ----------------->
          <Table
                  ref="orderManAgeTable"
                  border
                  :columns="dataMain.fwxmsfColumns"
                  :data="dataMain.fwxmsfData"
                  size="small"
                  :height="tableHeight"
                  :width="tableWidth"
                  highlight-row
                  tooltip-theme="light"
                  :loading="FwxmsftableLoading"
          >
            <template slot="value2"  slot-scope="props">
              <Input v-model="props.row.value2"
                     :active-change="false"
                     @on-blur="oldManSave('社区坐标信息',props)"
              />
            </template>
            <template slot="value3"  slot-scope="props">
              <Input v-model="props.row.value3"
                     :active-change="false"
                     @on-blur="oldManSave('社区坐标信息',props)"
              />
            </template>
            <template slot="value4"  slot-scope="props">
              <Input v-model="props.row.value4"
                     :active-change="false"
                     @on-blur="oldManSave('社区坐标信息',props)"
              />
            </template>
            <template slot="jd"  slot-scope="props">
              <Input v-model="props.row.jd"
                     :active-change="false"
                     @on-blur="oldManSave('社区坐标信息',props)"
              />
            </template>
            <template slot="wd"  slot-scope="props">
              <Input v-model="props.row.wd"
                     :active-change="false"
                     @on-blur="oldManSave('社区坐标信息',props)"
              />
            </template>
          </Table>
          <!---------------表格相关 END----------------->
        </Card>

      </Col>
    </Row>
    <!----------------------------------------- 主列表 END--------------------------------------->
    <Modal
            width="500px"
            v-model="showModal"
            title="政府信息公示"
            :footerHide="true"
            :mask-closable="false"
            :closable="true"
            @on-cancel="closeMod"
    >
      <i-form
              class="modHeight"
              ref="showModal"
              :model="addOrUpdateMain"
              :rules="validation_data"
              :label-width="100"
      >
        <FormItem label="公示信息：" prop="name">
          <Input type="textarea"
                  v-model="addOrUpdateMain.name"
                  maxlength="300"
                  clearable
                  placeholder="请输入"
                 show-word-limit
          />
        </FormItem>
      </i-form>
      <div class="ivu-modal-footer">
        <slot name="footer">
          <Button @click="closeMod">取 消</Button>
          <Button type="primary" @click="add">确 定</Button>
        </slot>
      </div>
    </Modal>
  </div>
</template>
<!--大屏数据维护-->
<script>
  import {
    getOneRightThreeList,
    getOneDtList,
    updateOneRightOne,
    updateOneRightThree,
    updateOnePxXx,
    updateoneRjXx,
    updateonePxZb,
    getOneRightOneListPage,
    updateoneRjZb,
    updateoneSqXx,
    updateoneSqZb,
    deleteOneRightOne,
    insertOneRightOne,
  } from "./dataMaintenance";

//todo
export default {
  name: "dataMaintenance",
  data() {
    return {
      //--------------------------主页面表格用--------------------------
      tableHeight: 0,
      tableWidth: "",
      validation_data: {
        name: [
          { required: true, message: "政府信息不能为空", trigger: "blur" },
        ],
      },
      dataMain: {
        jgyfwdxbdData:[],
        orderManNlData:[],
        rjzlData:[],
        fwryRsData:[],//服务人员结构信息人社
        fwJgRsData:[],//服务人员结构信息机构
        qxfwjgData:[],//区县服务结构信息
        fwxmsfData:[],//服务项目收费信息
        jgyfwdxbdColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "机构名称", key: "org", minWidth: 70, align: "center" },
          {title: "人数", slot: 'action', align: "center", minWidth: 70,},
        ],
        oldNlColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "学校名称", key: "name", minWidth: 120, align: "center" },
          {title: "教工人数", slot: 'value2', align: "center", minWidth: 70,},
          {title: "学员人数", slot: 'value3', align: "center", minWidth: 70,},
          {title: "应届毕业人数", slot: 'value4', align: "center", minWidth: 80,},
          {title: "应届就业人数", slot: 'value5', align: "center", minWidth: 80,},
          {title: "经度", slot: 'jd', align: "center", minWidth: 90,},
          {title: "纬度", slot: 'wd', align: "center", minWidth: 90,},
        ],
        rjzlColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "机构名称", key: "name", minWidth: 120, align: "center" },
          {title: "服务人员数", slot: 'value2', align: "center", minWidth: 70,},
          {title: "绑定服务老人数", slot: 'value3', align: "center", minWidth: 70,},
          {title: "日均服务人数", slot: 'value4', align: "center", minWidth: 80,},
          {title: "经度", slot: 'jd', align: "center", minWidth: 90,},
          {title: "纬度", slot: 'wd', align: "center", minWidth: 90,},
        ],
        fwryRsColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "公示信息", slot: "name", align: "center" },
          { title: "删除", slot: "action",width: 65, align: "center" },
        ],
        fwxmsfColumns: [
          { title: "序号", type: "index", width: 70, align: "center" },
          { title: "社区名称", key: "name", minWidth: 120, align: "center" },
          {title: "家庭病床数", slot: 'value2', align: "center", minWidth: 70,},
          {title: "医护人员数", slot: 'value3', align: "center", minWidth: 70,},
          {title: "签约服务人员数", slot: 'value4', align: "center", minWidth: 80,},
          {title: "经度", slot: 'jd', align: "center", minWidth: 90,},
          {title: "纬度", slot: 'wd', align: "center", minWidth: 90,},
        ],

      },
      //--------------------------主页面表格用 END--------------------------
      AgeLoading: false,
      tableJgLoading: false,
      jzhjLoading: false,
      RstableLoading: false,
      rjzlTableLoading: false,
      qxfwjgtableLoading: false,
      FwxmsftableLoading: false,
      showModal: false,
      addOrUpdateMain:{
        name:'',
      }
    };
  },
  methods: {
    //服务机构与服务对象绑定信息
    getFunjgyfwdxbd(){
      this.AgeLoading = true
      getOneRightThreeList().then(res=>{
        this.AgeLoading = false
        this.dataMain.jgyfwdxbdData = res;
      })
    },
    //培训学校坐标信息
    getFunNl(){
      this.tableJgLoading = true
      getOneDtList().then(res=>{
        let list = res.onePxXxVO;
        let listzb = res.onePxZbVO;
        this.dataMain.orderManNlData = [];
        for(let i=0;i<list.length;i++){
          for(let j=0;j<listzb.length;j++){
            if(list[i].name == listzb[j].name){
              list[i].jd = listzb[j].jd
              list[i].wd = listzb[j].wd
            }
          }
        }
        this.dataMain.orderManNlData = list;
        this.tableJgLoading = false
      })
    },
    //政府信息公示窗口
    getFunRs(){
      this.RstableLoading=true
      getOneRightOneListPage().then(res=>{
        this.RstableLoading=false
        this.dataMain.fwryRsData = res;
      })
    },
    //日间照料坐标信息
    getFunFwryJg(){
      this.rjzlTableLoading = true;
      getOneDtList().then(res=>{
        let list = res.oneRjXxVO;
        let listzb = res.oneRjZbVO;
        this.dataMain.rjzlData = [];
        for(let i=0;i<list.length;i++){
          for(let j=0;j<listzb.length;j++){
            if(list[i].name == listzb[j].name){
              list[i].jd = listzb[j].jd
              list[i].wd = listzb[j].wd
            }
          }
        }
        this.dataMain.rjzlData = list;
        this.rjzlTableLoading = false
      })
    },
    //社区坐标信息
    getFunFwxmsf(){
      this.FwxmsftableLoading = true
      getOneDtList().then(res=>{
        let list = res.oneSqXxVO;
        let listzb = res.oneSqZbVO;
        this.dataMain.fwxmsfData = [];
        for(let i=0;i<list.length;i++){
          for(let j=0;j<listzb.length;j++){
            if(list[i].name == listzb[j].name){
              list[i].jd = listzb[j].jd
              list[i].wd = listzb[j].wd
            }
          }
        }
        this.dataMain.fwxmsfData = list;
        this.FwxmsftableLoading = false
      })
    },
    oldManSave(type,props) {
      let data =  props.row;
      if(type == '服务机构与服务对象绑定信息'){
        updateOneRightThree(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunjgyfwdxbd()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '培训学校坐标信息'){
        updateOnePxXx(data).then(res =>{
          if(res.code == 0){
            updateonePxZb(data).then(res =>{
              if(res.code == 0){
                this.$Message.success("修改成功");
                this.getFunNl()
              }else{
                this.$Message.error("修改失败");
              }
            })
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '政府信息公示窗口'){
        updateOneRightOne(data).then(res =>{
          if(res.code == 0){
            this.$Message.success("修改成功");
            this.getFunRs()
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '日间照料机构坐标信息'){
        updateoneRjXx(data).then(res =>{
          if(res.code == 0){
            updateoneRjZb(data).then(res =>{
              if(res.code == 0){
                this.$Message.success("修改成功");
                this.getFunFwryJg()
              }else{
                this.$Message.error("修改失败");
              }
            })
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
      if(type == '社区坐标信息'){
        updateoneSqXx(data).then(res =>{
          if(res.code == 0){
            updateoneSqZb(data).then(res =>{
              if(res.code == 0){
                this.$Message.success("修改成功");
                this.getFunFwxmsf()
              }else{
                this.$Message.error("修改失败");
              }
            })
          }else{
            this.$Message.error("修改失败");
          }
        })
      }
    },
    //政府信息公示窗口
    addZfxx(){
      this.showModal = true;
      this.addOrUpdateMain.name ="";
      this.$refs["showModal"].resetFields();
    },
    //删除
    delZf(obj){
      deleteOneRightOne(obj).then(res=>{
        if(res.code == 0){
          this.$Message.success("删除成功");
          this.getFunRs()
        }else{
          this.$Message.error("删除失败");
        }
      })

    },
    closeMod(){
      this.showModal = false;
      this.$refs["showModal"].resetFields();
      this.addOrUpdateMain.name ="";
    },
    add(){
      this.$refs["showModal"].validate((valid) => {
          if (valid) {
            insertOneRightOne(this.addOrUpdateMain).then(res=>{
              if(res.code == 0){
                this.$Message.success("新增成功");
                this.getFunRs()
                this.closeMod()
              }else{
                this.$Message.error("新增失败");
              }
            })
          }
      })
    }
  },
  //todo
  //---------------------初始化加载 ----------------------
  mounted() {
    this.getFunjgyfwdxbd();
    this.getFunNl();
    this.getFunRs();
    this.getFunFwryJg();
    this.getFunFwxmsf();
    this.tableHeight = window.innerHeight * 0.5 - 90;
    console.log(this.tableHeight)
    window.onresize = () => {
      return (() => {
        this.tableHeight = window.innerHeight * 0.5 - 90;
      })();
    };
  },
  //---------------------初始化加载 END----------------------
};
</script>

<style scoped>

</style>
<style>
  .ivu-icon{
    left: 25px !important;
  }
</style>