import server from '../../util/server';
import context from "../../util/context";

/**
 * 政府信息公示窗口
 * @returns {*}
 */
export function getOneRightOneListPage() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneRightOneListPage',
    });
}

/**
 * 区县服务结构信息
 * @returns {Promise<unknown>}
 */
export function getOneLiftThreeList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLiftThreeList',
    });
}

/**
 * 服务项目收费信息
 * @returns {Promise<unknown>}
 */
export function getOneRightTwoList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneRightTwoList',
    });
}

/**
 * 服务机构与服务对象绑定信息
 * @returns {Promise<unknown>}
 */
export function getOneRightThreeList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneRightThreeList',
    });
}

/**
 * 服务人员结构信息 人社
 * @returns {Promise<unknown>}
 */
export function getOneLeftTwoOneList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftTwoOneList',
    });
}

/**
 * 服务人员结构信息 机构
 * @returns {Promise<unknown>}
 */
export function getOneLeftTwoTwoList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftTwoTwoList',
    });
}

/**
 * 老人结构信息 龄
 * @returns {Promise<unknown>}
 */
export function getOneLeftOneOneList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftOneOneList',
    });
}
/**
 * 老人结构信息 能
 * @returns {Promise<unknown>}
 */
export function getOneLeftOneTwoList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftOneTwoList',
    });
}
/**
 * 老人结构信息 居
 * @returns {Promise<unknown>}
 */
export function getOneLeftOneThreeList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneLeftOneThreeList',
    });
}

/**
 * 查询机构信息方法
 * @returns {Promise<unknown>}
 */
export function getOneDtList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOneDtList',
    });
}

/**
 *
 * @returns {Promise | Promise<unknown>}
 */
export function getOnefuryList(number) {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getOnefuryList',
        params: {
            number: number,
        },
    });
}


/***************************************************************修改***************************************************************/



/**
 * 修改老人结构信息 龄
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneLeftOneOne(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneLeftOneOne',
        params: data
    });
}
/**
 * 修改老人结构信息 能
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneLeftOneTwo(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneLeftOneTwo',
        params: data
    });
}

/**
 * 修改老人结构信息 居住环境
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneLeftOneThree(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneLeftOneThree',
        params: data
    });
}

/**
 * 修改服务人员结构信息 人社
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneLeftTwoOne(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneLeftTwoOne',
        params: data
    });
}

/**
 * 修改服务人员结构信息 机构
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneLeftTwoTwo(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneLeftTwoTwo',
        params: data
    });
}


/**
 * 修改区县服务结构信息
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneLiftThree(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneLiftThree',
        params: data
    });
}

/**
 * 服务项目收费信息
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneRightTwo(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneRightTwo',
        params: data
    });
}

/**
 * 服务机构与服务对象绑定信息
 * @returns {Promise<unknown>}
 */
export function updateOneRightThree(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneRightThree',
        params: data
    });
}

/**
 * 服务机构与服务对象绑定信息
 * @returns {Promise<unknown>}
 */
export function updateTwoTopBt(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateTwoTopBt',
        params: data
    });
}

/**
 * 城市规划养老床位缺口占比
 * @returns {Promise<unknown>}
 */
export function updateThreeLeft(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateThreeLeft',
        params: data
    });
}

/**
 * 修改第三屏右侧柱状图
 * @returns {Promise<unknown>}
 */
export function updateThreeRight(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateThreeRight',
        params: data
    });
}

/**
 * 修改第二屏最上面一排方法
 * @returns {Promise<unknown>}
 */
export function updateTwoTop(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateTwoTop',
        params: data
    });
}

/**
 * 修改培训机构详细
 * @returns {Promise | Promise<unknown>}
 */
export function updateOnePxXx(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOnePxXx',
        params: data
    });
}
/**
 * 修改日间照料详细
 * @returns {Promise | Promise<unknown>}
 */
export function updateoneRjXx(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateoneRjXx',
        params: data
    });
}
/**
 * 修改第一屏日间照料坐标
 * @returns {Promise | Promise<unknown>}
 */
export function updateoneRjZb(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateoneRjZb',
        params: data
    });
}
/**
 * 修改培训机构详细
 * @returns {Promise | Promise<unknown>}
 */
export function updateonePxZb(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateonePxZb',
        params: data
    });
}
/**
 * 修改政府信息公示窗口
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneRightOne(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneRightOne',
        params: data
    });
}
/**
 * 修改社区详细
 * @returns {Promise | Promise<unknown>}
 */
export function updateoneSqXx(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateoneSqXx',
        params: data
    });
}
/**
 * 修改社区坐标
 * @returns {Promise | Promise<unknown>}
 */
export function updateoneSqZb(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateoneSqZb',
        params: data
    });
}
/**
 * 修改养老院详细
 * @returns {Promise | Promise<unknown>}
 */
export function updateoneYlXx(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateoneYlXx',
        params: data
    });
}
/**
 * 修改养老院坐标
 * @returns {Promise | Promise<unknown>}
 */
export function updateOneYlZb(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateOneYlZb',
        params: data
    });
}

/**
 * 修改护理站详细
 * @returns {Promise | Promise<unknown>}
 */
export function updateoneHlXx(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateoneHlXx',
        params: data
    });
}
/**
 * 修改护理站坐标
 * @returns {Promise | Promise<unknown>}
 */
export function updateoneHlZb(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateoneHlZb',
        params: data
    });
}
/**
 * 修改第二屏右侧年
 * @returns {Promise | Promise<unknown>}
 */
export function updateTwoRightYear(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateTwoRightYear',
        params: data
    });
}
/**
 * 修改第二屏右侧月
 * @returns {Promise | Promise<unknown>}
 */
export function updateTwoRight(data) {
    return server.put({
        url: context.baseUrl + '/base/onerightone/updateTwoRight',
        params: data
    });
}

/**
 * 删除政府信息公示窗口
 * @returns {Promise | Promise<unknown>}
 */
export function deleteOneRightOne(data) {
    return server.del({
        url: context.baseUrl + '/base/onerightone/deleteOneRightOne',
        params: data
    });
}
/**
 * 删除政府信息公示窗口
 * @returns {Promise | Promise<unknown>}
 */
export function insertOneRightOne(data) {
    return server.post({
        url: context.baseUrl + '/base/onerightone/insertOneRightOne',
        params: data
    });
}
/** **********************************二屏 开始 *******************************/

/**
 * 中间顶部
 * @returns {*}
 */
export function getTwoTopList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoTopList',
    });
}

/**
 * 中间饼图
 * @returns {Promise<unknown>}
 */
export function getTwoTopBtList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoTopBtList',
    });
}

/**
 * 左侧趋势图 月
 * @returns {Promise<unknown>}
 */
export function getTwoRightList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoRightList',
    });
}

/**
 * 左侧趋势图 年
 * @returns {Promise<unknown>}
 */
export function getTwoRightYearList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoRightYearList',
    });
}

/**
 * 工单流水
 * @returns {Promise<unknown>}
 */
export function getTwoGdlsList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoGdlsList',
    });
}

/**
 * 服第三屏左侧饼图方法
 * @returns {Promise<unknown>}
 */
export function getThreeLeftList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getThreeLeftList',
    });
}

/**
 * 柱状图
 * @returns {Promise<unknown>}
 */
export function getThreeRightList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getThreeRightList',
    });
}
/** **********************************二屏 结束 *******************************/